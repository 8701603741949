<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <b-row class="mail">
    <b-col lg="6">
      <h3>{{ $t('setting.emailVerify') }}</h3>
      <p>
        {{ $t('setting.verifyDisclaimer') }}
      </p>
      <div class="input-email">
        <b-input type="email" :value="UserInfo.email" readonly></b-input>
        <p>
          <img src="~@/assets/images/icons/icon-active.png" alt="" />
          {{ $t('setting.verified') }}
        </p>
      </div>
    </b-col>
    <b-col lg="6" class="section-pass">
      <h3>{{ $t('setting.changePassword') }}</h3>
      <b-form @submit.prevent="onChangePassword" class="text-center">
        <div class="group-input text-left">
          <p>{{ $t('setting.oldPassword') }}</p>
          <b-input :type="typeIpt.one" v-model="form.User_Password"></b-input>
          <img
            src="~@/assets/images/icons/icon-eye-close.png"
            alt=""
            class="pass-eye"
            @click="onChangeType('one')"
          />
        </div>
        <div class="group-input text-left">
          <p>{{ $t('setting.newPassword') }}</p>
          <b-input
            :type="typeIpt.two"
            v-model="form.User_New_Password"
          ></b-input>
          <img
            @click="onChangeType('two')"
            src="~@/assets/images/icons/icon-eye-close.png"
            alt=""
            class="pass-eye"
          />
        </div>
        <div class="group-input text-left">
          <p>{{ $t('setting.confirmPassword') }}</p>
          <b-input
            :type="typeIpt.three"
            v-model="form.User_Re_New_Password"
          ></b-input>
          <img
            @click="onChangeType('three')"
            src="~@/assets/images/icons/icon-eye-close.png"
            alt=""
            class="pass-eye"
          />
        </div>
        <p class="warning">
          <img src="~@/assets/images/icons/icon-warning.png" alt="" />
          {{ $t('setting.changePasswordDisclaimer') }}
        </p>
        <b-button type="submit" class="btnNoStyle custom-btn">
          {{ $t('setting.saveChanges') }}
        </b-button>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'MailPage',
  data() {
    return {
      typeIpt: {
        one: 'password',
        two: 'password',
        three: 'password',
      },
      form: {
        User_Password: '',
        User_New_Password: '',
        User_Re_New_Password: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      UserInfo: 'userinfo/UserInfo',
    }),
  },
  methods: {
    ...mapMutations(['onload', 'outload']),
    onChangeType(key) {
      this.typeIpt[key] = this.typeIpt[key] == 'password' ? 'text' : 'password';
    },
    onChangePassword() {
      const { User_Password, User_New_Password } = this.form;
      if (User_Password == User_New_Password) {
        this.form = {
          User_Password: User_Password,
          User_New_Password: '',
          User_Re_New_Password: '',
        };
        this.$toastr.e(
          'The new password must be different from the current password',
          this.$t('app.error')
        );
        return;
      }
      this.onload();
      this.$store.dispatch('userinfo/req_postChangePassword', this.form);
      this.outload();
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'userinfo/CHANGE_PASSWORD_SUCCESS':
          this.$store.commit('auth/LOGOUT_SUCCESS');
          this.$router.push('/login');
          break;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss">
.mail {
  height: 100%;
  h3 {
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 10px;
    @media (max-width: 992px) {
      margin-bottom: 15px;
    }
  }
  p {
    color: #d4dff4;
    font-size: 16px;
    font-weight: 500;
    min-height: 50px;
    margin-bottom: 0px;
    @media (max-width: 992px) {
      min-height: inherit;
    }
  }
  .input-email {
    position: relative;
    margin-top: 20px;
    @media (max-width: 992px) {
      margin-top: 15px;
      margin-bottom: 25px;
    }
    input {
      border: 0;
      background: #1b2c57;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
      color: #fff;
      height: 40px;
    }
    p {
      position: absolute;
      top: -6px;
      right: 15px;
      margin-bottom: 0px;
      display: flex;
      align-items: center;
      color: #ffcb12;
      img {
        margin-right: 5px;
      }
      @media (max-width: 992px) {
        top: 10px;
      }
    }
  }
  .section-pass {
    border-left: 1px solid #6a6a6a;
    height: 100%;
    padding-left: 20px;
    @media (max-width: 992px) {
      border-left: 0px;
      padding-left: 15px;
    }
    h3 {
      color: #ffffff;
      font-size: 18px;
    }
    .group-input {
      position: relative;
      margin-top: 20px;
      p {
        color: #d4dff4;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
      }
      input {
        border: 0;
        background: #1b2c57;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
        color: #fff;
        padding-right: 20px;
        height: 40px;
      }
      .pass-eye {
        position: absolute;
        bottom: 12px;
        right: 15px;
        width: 16px;
        margin: auto;
        z-index: 1050;
        cursor: pointer;
      }
    }
    .warning {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffcb12;
      margin-bottom: 0px;
      margin-top: 20px;
      font-size: 14px;
      img {
        margin-right: 10px;
      }
    }
    .text-description {
      color: #d4dff4;
      font-size: 14px;
      font-weight: 500;
      margin-top: 20px;
    }
  }
  .custom-btn {
    width: 80%;
    margin: 10px auto;
    border-radius: 4px;
    background: var(
      --button-2,
      linear-gradient(135deg, #0ec5ff 0%, #3964fb 82.81%)
    );
    box-shadow: -2px 2px 5px #0ec5ff, 2px 2px 5px #0ec5ff, 2px -2px 5px #3964fb,
      -2px -2px 5px #0ec5ff;
    font-weight: 500;
    height: 50px;
    filter: blur(0.3px);
  }
}
</style>
