<template>
  <b-row class="setting">
    <b-col lg="12">
      <b-button
        class="btnNoStyle back"
        @click="$router.push({ name: 'crash' })"
      >
        <img src="~@/assets/images/icons/icon-prev-white.png" alt="" />
        {{ $t('setting.goback') }}
      </b-button>
    </b-col>
    <b-col lg="3">
      <div class="setting-menu">
        <b-button
          class="btnNoStyle"
          :class="activeTab === 'mail' ? 'active' : ''"
          @click="activeTab = 'mail'"
        >
          {{ $t('setting.credentials') }}
        </b-button>
        <b-button
          class="btnNoStyle"
          :class="activeTab === 'security' ? 'active' : ''"
          @click="activeTab = 'security'"
        >
        {{ $t('setting.security') }}
        </b-button>
      </div>
    </b-col>
    <b-col lg="9">
      <div class="setting-content">
        <Mail v-if="activeTab === 'mail'" />
        <Security v-if="activeTab === 'security'" />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Mail from "@/components/Setting/Mail.vue";
import Security from "@/components/Setting/Security.vue";

export default {
  name: "SettingPageView",
  components: { Mail, Security },
  data() {
    return {
      activeTab: "mail",
    };
  },

};
</script>

<style lang="scss" scoped>
.setting {
  padding: 30px 50px;

  @media (max-width: 992px) {
    padding: 30px 20px;
  }

  @media (max-width: 576px) {
    padding: 30px 10px;
  }

  .back {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    @media (max-width: 576px) {
      margin-bottom: 15px;
    }

    img {
      margin-right: 30px;
    }
  }

  .setting-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #081b3c;
    -webkit-backdrop-filter: blur(15.5px);
    backdrop-filter: blur(15.5px);
    border-radius: 4px;
    padding: 15px;
    @media (max-width: 992px){
      flex-direction: row;
      margin-bottom: 30px;
    }

    button {
      width: 100%;
      padding: 10px 0px 10px 15px;
      text-align: left;
      margin-bottom: 10px;
      height: 50px;
      @media (max-width: 992px){
        background-color: rgba(27,72,187,.2) !important;
        text-align: center;
      }

      &.active {
        background: linear-gradient(
          91.46deg,
          #0ec5ff 1.08%,
          #3964fb 83%
        ) !important;
        border-radius: 4px;
      }

      &:hover {
        background: linear-gradient(
          91.46deg,
          #0ec5ff 1.08%,
          #3964fb 83%
        ) !important;
        border-radius: 4px;
      }
    }
  }

  .setting-content {
    background: #081b3c;
    -webkit-backdrop-filter: blur(15.5px);
    backdrop-filter: blur(15.5px);
    border-radius: 4px;
    padding: 30px 15px;
    height: 100%;
    min-height: 400px;
  }
}
</style>
