<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <b-row class="security">
    <b-col lg="6" class="authentication">
      <h3>{{ $t('setting.twoFA') }}</h3>
      <p class="text-description">
        {{ $t('setting.downloadInstruction') }}
      </p>
      <p class="text-description">
        {{ $t('setting.scanInstruction') }}
      </p>
      <div class="qr-img">
        <img
          v-lazy="
            !UserAuth.enable
              ? UserAuth.qr
              : require('@/assets/images/qr-hidden.png')
          "
          alt=""
        />
      </div>
    </b-col>
    <b-col lg="6">
      <div class="group-input">
        <p>{{ $t('setting.secretKey') }}</p>
        <b-input
          type="text"
          :value="!UserAuth.enable ? UserAuth.secret : '********'"
          readonly
        ></b-input>
        <b-button
          v-if="!UserAuth.enable"
          class="pass-eye-btn"
          v-clipboard:copy="UserAuth.secret"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          <img src="~@/assets/images/icons/icon-copy.png" alt="" />
        </b-button>
      </div>
      <p class="text-description">
        {{ $t('setting.writeInstruction') }}
      </p>
      <p class="text-description text-center">{{ $t('setting.verificationCode') }}</p>
      <b-form @submit.prevent="changAuth" autocomplete="off">
        <div class="group-input">
          <b-input
            type="text"
            pattern="\d*"
            maxlength="6"
            v-model.trim.number="authCode"
            @keyup="next"
            role="presentation"
            autocomplete="off"
            name="new-password"
            id="code0"
          ></b-input>
        </div>

        <div class="group-input-verification" v-if="false">
          <b-input
            type="text"
            pattern="\d*"
            maxlength="6"
            v-model.trim.number="secret[0]"
            @keyup="next"
            role="presentation"
            autocomplete="off"
            name="code0"
            id="code0"
          ></b-input>
          <b-input
            type="text"
            pattern="\d*"
            maxlength="1"
            v-model.trim.number="secret[1]"
            @keyup="next"
            role="presentation"
            autocomplete="off"
            name="code1"
            id="code1"
          ></b-input>
          <b-input
            type="text"
            pattern="\d*"
            maxlength="1"
            v-model.trim.number="secret[2]"
            @keyup="next"
            role="presentation"
            autocomplete="off"
            name="code2"
            id="code2"
          ></b-input>
          <b-input
            type="text"
            pattern="\d*"
            maxlength="1"
            v-model.trim.number="secret[3]"
            @keyup="next"
            role="presentation"
            autocomplete="off"
            name="code3"
            id="code3"
          ></b-input>
          <b-input
            type="text"
            pattern="\d*"
            maxlength="1"
            v-model.trim.number="secret[4]"
            @keyup="next"
            role="presentation"
            autocomplete="off"
            name="code4"
            id="code4"
          ></b-input>
          <b-input
            type="text"
            pattern="\d*"
            maxlength="1"
            v-model.trim.number="secret[5]"
            role="presentation"
            autocomplete="off"
            name="code5"
            id="code"
          ></b-input>
        </div>
        <b-button
          class="custom-btn"
          type="submit"
          :class="UserAuth.enable ? 'disable' : 'enable'"
        >
          {{ UserAuth.enable ? $t('setting.disable') : $t('setting.enable') }}
        </b-button>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "SecurityPage",
  data() {
    return {
      authCode: "",
      typeIpt: true,
      secret: [],
    };
  },
  computed: {
    ...mapGetters({
      UserAuth: "userinfo/UserAuth",
    }),
  },
  methods: {
    ...mapMutations(["onload", "outload"]),
    changAuth() {
      this.$store.dispatch("userinfo/req_postAuth", {
        authCode: this.authCode,
        secret: this.UserAuth.secret,
      });
    },
    onCopy: function (e) {
      this.$toastr.s(e.text, this.$t('setting.copied'));
    },
    onError: function (e) {
      this.$toastr.e(e.text, this.$t('setting.copyfailed'));
    },
    next(e) {
      if (e.target.value && e.target.value.length <= 5) {
        const data = e.target.value;
        const ArrayCode = data.trim().split(/\s/);
        for (let i = 0; i <= 5; i++) {
          this.secret[i] = ArrayCode[i];
        }
        return;
      }
      if (e.code == "Backspace") {
        e.target?.previousSibling?.focus();
        return;
      }
      e.target?.nextSibling?.focus();
    },
  },
  created() {
    this.$store.dispatch("userinfo/req_getAuth");
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "userinfo/CHANGE_AUTH_SUCCESS":
          this.$store.dispatch("userinfo/req_getAuth");
          break;
      }
    });
  },
  async mounted() {
    this.onload();
    await new Promise((resolve) => setTimeout(resolve, 300));
    this.outload();
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss">
.security {
  height: 100%;
  h3 {
    color: #ffffff;
    font-size: 18px;
  }
  .group-input {
    position: relative;
    margin-top: 20px;
    p {
      color: #d4dff4;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;
    }
    input {
      border: 0;
      background: #1b2c57;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
      color: #fff;
      padding-right: 20px;
      font-size: 18px;
      font-weight: 500;
      height: 40px;
    }
    .pass-eye-btn {
      position: absolute;
      bottom: 0;
      right: 15px;
      width: 16px;
      margin: auto;
      z-index: 1050;
      width: 40px;
      height: 40px;
      padding: 0;
      background: transparent !important;
      border: 0;
      outline: none !important;
      box-shadow: none !important;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
      }
    }
  }
  .warning {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffcb12;
    margin-bottom: 0px;
    margin-top: 20px;
    font-size: 14px;
    img {
      margin-right: 10px;
    }
  }
  .text-description {
    color: #d4dff4;
    font-size: 14px;
    font-weight: 500;
    margin-top: 20px;
  }
  .qr-img {
    width: 100%;
    height: 100%;
    max-width: 300px;
    max-height: 300px;
    padding: 15px;
    background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
    border-radius: 4px;
    margin: 20px auto 0px;
    img {
      width: 100%;
      height: 100%;
      max-width: 300px;
      object-fit: scale-down;
    }
  }
  .authentication {
    border-left: 1px solid #6a6a6a;
    height: 100%;
    padding-left: 20px;
    @media (max-width: 992px){
      height: auto;
      border-left: 0px;
      padding-left: 15px;
    }
  }
  .custom-btn {
    width: 100%;
    border-radius: 4px;
    background: var(
      --button-2,
      linear-gradient(135deg, #0ec5ff 0%, #3964fb 82.81%)
    );
    box-shadow: -2px 2px 5px #0ec5ff, 2px 2px 5px #0ec5ff, 2px -2px 5px #0ec5ff,
      -2px -2px 5px #0ec5ff;
    font-weight: 500;
    margin-top: 20px;
    height: 50px;
    filter: blur(0.3px);
    &.disable {
      background: var(
        --button-2,
        linear-gradient(135deg, #ff0e0e 0%, #fb3949 82.81%)
      );
      box-shadow: -2px 2px 5px #ff0e0e, 2px 2px 5px #ff0e0e,
        2px -2px 5px #ff0e0e, -2px -2px 5px #ff0e0e;
    }
  }
  .group-input-verification {
    display: flex;
    justify-content: center;
    input {
      width: 40px;
      height: 40px;
      background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
      border: 0;
      margin: 0px 10px;
      text-align: center;
    }
  }
}
</style>
